// COIN_dashboard\client\src\components\side-navbar\DashboardSideNavBar.js

// Side navbar icons need to be fixed ?

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
// import user from "../../StyleCSS/icons/user.png";
import signOut_icon from "../../StyleCSS/icons/signOut.png";
import "../../StyleCSS/SideNavbar.css";
import { logout } from "../../reducers/authUserSlice";
import { useDispatch } from "react-redux";

function SideNavBar(props) {
  const [selectedRoute, setSelectedRoute] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    // if location changes, update selected route which decides which item to highlight.
    let route = location.pathname.match(/\/dashboard\/(.*)/);
    if (route && route.length >= 2) {
      setSelectedRoute(route[1]);
    } else {
      setSelectedRoute(null);
    }
  }, [location]);

  return (
    <div id="MainNavContainerDashBoard">
      <ul id="dashboardUL">
        <div id="overflow-controll">
          {props.routes
            ? props.routes.map((path, index) => {
                return (
                  <li key={path.pathName} className="dashListItem">
                    <Link to={path.route_path} className="dashLink">
                      <div
                        className={
                          selectedRoute === path.route_path
                            ? "circleDiv"
                            : "invisDiv"
                        }
                      >
                        <img
                          className="sidemenu-icon"
                          src={path.icon}
                          alt="s"
                        />
                      </div>
                      <p className="dashBoardTitle">
                        {path.pathName.toUpperCase() !== "ARCHIVE"
                          ? path.pathName.toUpperCase()
                          : "ARKIV"}
                      </p>
                    </Link>
                  </li>
                );
              })
            : null}
        </div>
      </ul>

      <div id="myAccountItem">
        <div
          className="dashLink"
          id="logoutMenuButton"
          onClick={() => {
            dispatch(logout());
          }}
        >
          <div className={"invisDiv"}>
            <img id="accImg" src={signOut_icon} alt="s" />
          </div>
          <p className="dashBoardTitle">LOGGA UT</p>
        </div>
      </div>

      {/* KONTOSIDA IMPLEMENTERAS SENARE */}
      {/* <div id="myAccountItem">
        <Link className="dashLink" to={"myaccount"}>
          <div
            className={selectedRoute === "myaccount" ? "circleDiv" : "invisDiv"}
          >
            <img id="accImg" src={user} alt="s" />
          </div>
          <p className="dashBoardTitle">KONTO</p>
        </Link>
      </div> */}
    </div>
  );
}

export default SideNavBar;
