import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Landing(props) {
  const isAuthenticated = useSelector(
    (state) => state.authUser.isAuthenticated
  );
  return isAuthenticated ? (
    <Navigate to="/dashboard" />
  ) : (
    <Navigate to="/login" />
  );
}

export default Landing;
