



import {createSlice } from '@reduxjs/toolkit'

const initialState = {
    loaded: false
}

const PBILoaded = createSlice({
    name: 'PBILoaded',
    initialState,
    reducers: {
        setPBICredentialsLoaded: (state, action) => {
            state.loaded = action.payload
        },
        
    }
})


export const {setPBICredentialsLoaded} = PBILoaded.actions

export default PBILoaded.reducer

 
 