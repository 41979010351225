import { combineReducers } from "redux";
import authUser from "./authUserSlice.js";
import PBICredSlice from "./PBICredSlice.js";
import PBILoadedSlice from "./PBILoadedSlice.js";

export default combineReducers({
  authUser: authUser,
  PBICred: PBICredSlice,
  PBILoaded: PBILoadedSlice,
});
