// COIN_dashboard\client\src\requests\loginRequests.js

export function loginReq(email, password) {
	return new Promise((resolve, reject) => {
		return fetch("/api/users/login", {
			method: "POST",
			body: JSON.stringify({
				email: email,
				password: password,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => {
				if (res.status === 200) {
					res.json().then((data) => {
						resolve(data);
					});
				} else {
					res.json().then((resp) => {
						reject(resp);
					});
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function eIdBeginReq() {
	return new Promise((resolve, reject) => {
		return fetch("/api/users/eIdBegin", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => {
				if (res.status === 200) {
					res.json().then((data) => {
						resolve(data);
					});
				} else {
					res.json().then((resp) => {
						reject(resp);
					});
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function eidloginReq(transaction_id) {
	return new Promise((resolve, reject) => {
		return fetch("/api/users/eidlogin", {
			method: "POST",
			body: JSON.stringify({
				transaction_id: transaction_id,
			}),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => {
				if (res.status === 200) {
					res.json().then((data) => {
						resolve(data);
					});
				} else {
					res.json().then((resp) => {
						reject(resp);
					});
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function logoutReq(email, password) {
	return new Promise((resolve, reject) => {
		return fetch("/api/users/logout", {
			method: "POST",
			body: JSON.stringify({}),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => {
				if (res.status === 200) {
					resolve("OK");
				} else {
					res.json().then((resp) => {
						reject(resp);
					});
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function checkLoggedinReq() {
	return new Promise((resolve, reject) => {
		return fetch("/api/users/checkLoggedin", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => {
				if (res.status === 200) {
					res.json().then((data) => {
						resolve(data);
					});
				} else {
					res.json().then((resp) => {
						reject(resp);
					});
				}
			})
			.catch((err) => {
				reject(err);
			});
	});
}
