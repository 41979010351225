/*
 This is a public route that redirects to login if user is not logged in.
*/

import React from "react";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  checkLoggedin,
  resetAuthUserError,
} from "../../reducers/authUserSlice.js";
import { useEffect } from "react";

const PublicRoute = ({ component: Component }) => {
  var redirect = "/login";
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state) => state.authUser.isAuthenticated
  );

  useEffect(() => {
    dispatch(checkLoggedin());
    return function cleanLogin() {
      dispatch(resetAuthUserError());
    };
  }, []);

  if (isAuthenticated === true) {
    return <Component />;
  } else {
    return <Navigate to={redirect} />;
  }
};

export default PublicRoute;
