import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Request } from "../requests/makeRequest";

export const getPBICredentials = createAsyncThunk(
  "PBICred/getPBICredentials",
  async (params, state) => {
    let req = new Request(() => {
      return fetch("/api/users/getPowerBICredentials", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
    });
    const resp = await req.makeRequest();
    return resp.json;
  }
);

const initialState = {
  embedUrl: null,
  accessToken: null,
  reportID: null,
  pages: [],
  status: "idle",
  error: null,
  message: null,
};

const PBICredentials = createSlice({
  name: "PBICred",
  initialState,
  reducers: {
    clearCredentials: (state, action) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPBICredentials.fulfilled, (state, action) => {
        state.embedUrl = action.payload.embedUrl;
        state.accessToken = action.payload.accessToken;
        state.reportID = action.payload.reportID;
        state.pages = action.payload.reportPages;
        state.status = "idle";
        state.message = "PBI credentials fetched";
        state.error = null;
      })
      .addCase(getPBICredentials.pending, (state, action) => {
        state.status = "pending";
        state.message = "fetching PBI credentials";
      })
      .addCase(getPBICredentials.rejected, (state, action) => {
        state.status = "rejected";
        state.embedUrl = null;
        state.accessToken = null;
        state.reportID = null;
        state.pages = [];
        state.message = "failed to fetch PBI credentials";
        state.error = action.error.message;
      });
  },
});

export const { clearCredentials } = PBICredentials.actions;

export default PBICredentials.reducer;
