// COIN_dashboard\client\src\App.js

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import StateLoader from "./state.loader.js";
import rootReducer from "./reducers";
import thunk from "redux-thunk";

import "./bootstrap/bootstrap.min.css";
import "./custom.css";
import "./StyleCSS/box-tableGraph.css";
import "./StyleCSS/kundvy.css";
import "./StyleCSS/formSheet.css";
import "./StyleCSS/OperationsView.css";
import "./StyleCSS/adminView.css";
import "./StyleCSS/adminSideMenu.css";
import "./StyleCSS/fileSystem.css";
import "./StyleCSS/archive.css";
import "./StyleCSS/myAccount.css";

//import Tabulator stylesheet
// import "tabulator-tables/dist/css/tabulator.css";
import "./StyleCSS/standardTableTabulator.css";
import "./StyleCSS/customerTableTabulator.css";
import "./StyleCSS/fileTableTabulator.css";

import Landing from "./components/public/Landing";
import Login from "./components/auth/Login";
// import PrivateRoute from "./components/private-route/PrivateRoute";
import PublicRoute from "./components/private-route/publicRoute";
import dashboardLanding from "./components/dashboard/dashboardLanding.js";

// Testing AAI:
// Import your Azure Application Insights Logging instance
import Azure_Application_Insights_Logging from './Azure_Application_Insights_Logging';

// Intiating the stateloader class from state.loader.js
const stateLoader = new StateLoader();

// Setting thunk middleware
const middleware = [thunk];

// Creating the store with reducers and actions
export const store = createStore(
  rootReducer,
  stateLoader.loadState(),
  compose(
    applyMiddleware(...middleware),
    window.REDUX_DEVTOOLS_EXTENSION
      ? window.REDUX_DEVTOOLS_EXTENSION()
      : (f) => f
  )
);

// Subscribing the state, updating it in sessionStorage in paralell
// Stores logged in user so that redirecting works when reloading.
store.subscribe(() => {
  stateLoader.saveState(store.getState());
});

// window.store = store;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { redirect: false };

    // Testing AAI:
    // Global error handler for unhandled exceptions
    window.onerror = function (message, source, lineno, colno, error) {
      if (Azure_Application_Insights_Logging) {
        Azure_Application_Insights_Logging.trackException({ exception: error });
      }
      // Optionally, rethrow the error if you want it to appear in the console as well
      throw error;
    };
  }

  componentWillUnmount() {
    // stateLoader.saveState()
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <Provider store={store}>
          <Router>
            <h1> NÅGOT GICK FEL, FÖRSÖK IGEN SENARE </h1>
          </Router>
        </Provider>
      );
    } else {
      return (
        <Provider store={store}>
          <div id="App">
            <Router>
              <Routes>
                <Route path="/" element={<Landing />} />
                <Route path="/login/*" element={<Login />} />
                <Route
                  path="/dashboard/*"
                  element={
                    <PublicRoute
                      path="/dasboard"
                      component={dashboardLanding}
                    />
                  }
                />
                <Route path="*" element={<Landing />} />
              </Routes>
            </Router>
          </div>
        </Provider>
      );
    }
  }
}

export default App;
