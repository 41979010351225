// COIN_dashboard\client\src\Azure_Application_Insights_Logging.js

/*
serves as a setup and configuration module for Azure Application Insights in your React application. It enhances your logging capabilities by not only allowing standard browser console logging but also sending these logs to Azure Application Insights for centralized monitoring and analysis.

Summary of the File's Purpose
Setup Azure Application Insights: The file sets up Azure Application Insights with your application. It uses an environment variable for the instrumentation key, which is a best practice for managing configuration and sensitive data.
Automatic Page View Tracking: It automatically tracks and sends page view information, which helps in analyzing user interactions and traffic on your application.
Enhanced Logging: By overriding the console.log function, the file enhances the standard logging functionality. Every log message is sent to Azure Application Insights as a trace, in addition to being logged in the browser console.
Centralized Monitoring: This setup allows for centralized logging and monitoring of your application's runtime behavior, error tracking, and performance metrics in Azure, while maintaining the convenience of local console logs for development and debugging.
This approach offers a balanced solution between local visibility of logs (for development and immediate debugging) and centralized logging (for long-term monitoring, analysis, and alerting).
*/

// Import the Application Insights module from the Azure Application Insights JavaScript SDK.
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// Define the variable in a scope accessible throughout the file
let Azure_Application_Insights_Logging;

// Check if the instrumentation key is available
const instrumentationKey = process.env.REACT_APP_LOGGING_AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY;
if (!instrumentationKey) {
  console.error("Azure Application Insights Instrumentation Key is not set.");
  // Handle the missing key appropriately. You might choose to disable AAI logging or provide a default behavior.
} else {// Initialize the Application Insights instance with configuration.
  Azure_Application_Insights_Logging = new ApplicationInsights({
    config: {
      // The instrumentation key is retrieved from environment variables, 
      // which uniquely identifies the Azure Application Insights resource.
      instrumentationKey: instrumentationKey,
      // Additional configuration options can be set here.
    }
  });
  
  // Load and activate Application Insights with the specified configuration.
  Azure_Application_Insights_Logging.loadAppInsights();
  
  // Automatically track and send page view information to Azure Application Insights.
  Azure_Application_Insights_Logging.trackPageView();
  
  // Override the default console.log function.
  const originalConsoleLog = console.log;
  console.log = function(...args) {
    // Send the log message to Azure Application Insights as a trace.
    // This allows the logs to be monitored and analyzed in Azure.
    Azure_Application_Insights_Logging.trackTrace({ message: args.join(' ') });
  
    // Continue the normal behavior of console.log by logging to the browser console.
    // This ensures that the logs are still visible in the browser's inspector tool.
    originalConsoleLog.apply(console, args);
  };
}

// Export the configured Azure Application Insights instance.
// This allows it to be imported and used in other parts of the application.
export default Azure_Application_Insights_Logging;

/*
Below is similar to above but without pushing regular console.log logs to AAI in the cloud
*/
/*
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const Azure_Application_Insights_Logging = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_LOGGING_AZURE_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    // Additional configuration options can be set here
  }
});

Azure_Application_Insights_Logging.loadAppInsights();
Azure_Application_Insights_Logging.trackPageView(); // Automatically track page views

// Export this to track custom events, exceptions, etc.
export default Azure_Application_Insights_Logging;
*/