import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
// import logga from "../../components/auth/Logga_vit_ren.png";
import logga from "../../components/auth/Evisens_Logga_vit_ren.png";
import desktopPic from "../../components/auth/desktopPic.png";
// import logoAndtext from "../../components/auth/logAndText.png";
import logoAndtext from "../../components/auth/EvisensLogoAndText.png";
import { Navigate } from "react-router-dom";
import { login, eIdBegin, eidlogin, resetAuthUserError } from "../../reducers/authUserSlice.js";
import { useDispatch, useSelector } from "react-redux";

function Login(props) {
	const dispatch = useDispatch();
	const [email, setEmail] = useState(null);
	const [password, setPassword] = useState(null);
	const isAuthenticated = useSelector((state) => state.authUser.isAuthenticated);
	const eidPending = useSelector((state) => state.authUser.eidPending);
	const statedata = useSelector((state) => state.authUser.data);

	const authuserReqStatus = useSelector((state) => state.authUser.status);
	const authErrors = useSelector((state) => state.authUser.error);
	const [emailError, setEmailError] = useState(null);
	const [loginError, setLoginError] = useState(false);

	const [searchParams, setSearchParams] = useSearchParams();
	const transaction_id = searchParams.get("transaction_id");

	useEffect(() => {
		return function cleanUp() {
			console.log("effect cleanup");
			dispatch(resetAuthUserError());
		};
	}, []);

	useEffect(() => {
		if (authuserReqStatus === "rejected") {
			if (authErrors && authErrors.email) {
				setEmailError(authErrors.email);
				setLoginError(false);
			} else {
				setEmailError(null);
				setLoginError(true);
			}
		} else {
			setEmailError(null);
			setLoginError(false);
		}
	}, [authuserReqStatus]);

	const onSubmit = (e) => {
		e.preventDefault();
		dispatch(login({ email: email, password: password }));
	};

	const onEIdSubmit = (e) => {
		e.preventDefault();
		setSearchParams("transaction_id", "");
		dispatch(eIdBegin());
	};

	if (eidPending) {
		if (transaction_id) {
			dispatch(eidlogin({ transaction_id: transaction_id }));
			return "";
		}
		else {
			window.location.href = statedata["accessUrl"];
			return "";
		}
	}
	if (isAuthenticated) {
		// error if user is undefined.
		return <Navigate to={"/dashboard"} />;
	} else {
		return (
			<div>
				<div className="textAndLogo">
					<img src={logoAndtext} style={{ maxWidth: "230px" }} alt=""></img>
				</div>

				<div
					style={{
						width: "100%",
						height: "100%",
						position: "absolute",
						display: "flex",
					}}
				>
					<div className="container loginscreen">
						<div className="row">
							<div className="loginContainer">
								<div id="logoContainer">
									<img id="coin-logo" src={logga} alt="loggo"></img>
								</div>

								{/* <div className="loginText">
                  <h3 className="login-h3"> Logga in till analysatorn</h3>
                </div> */}

								<form noValidate onSubmit={onSubmit}>
									<div className="input-field">
										<input
											onChange={(e) => setEmail(e.target.value)}
											// value={email}
											placeholder={"E-mail"}
											id="email"
											type="email"
											className="loginInput"
										/>
										{emailError ? (
											<span style={{ color: "red" }}>{emailError}</span>
										) : null}
									</div>
									<div className="input-field">
										<input
											onChange={(e) => setPassword(e.target.value)}
											// value={password}
											placeholder={"Lösenord"}
											id="password"
											type="password"
											className="loginInput"
										/>
									</div>
									<div className="loginButtonContainer">
										<button type="submit" className="loginBtn">
											Login
										</button>
									</div>
								</form>
								{loginError ? (
									<span style={{ color: "red" }}>Kunde inte logga in</span>
								) : null}
								<div>
									<p />
									<form noValidate onSubmit={onEIdSubmit}>
										<div className="loginButtonContainer">
											<button type="submit" className="loginBtn">
												BankId
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>

					<div
						className="picture"
						style={{
							backgroundColor: "white",
							width: "60%",
							alignItems: "center",
							alignContent: "center",
							display: "flex",
						}}
					>
						<img
							className="firstPagePic"
							src={desktopPic}
							style={{ marginLeft: "6vh" }}
							alt=""
						/>
					</div>
				</div>
			</div>
		);
	}
}

export default Login;
