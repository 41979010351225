// import store from "./store";

export default class StateLoader {
  loadState() {
    try {
      let serializedState = window.sessionStorage.getItem("localStateName");

      if (serializedState === null) {
        return this.initializeState();
      }

      return JSON.parse(serializedState);
    } catch (err) {
      return this.initializeState();
    }
  }

  saveState(state) {
    let temp_state = Object.assign({}, state);
    // temp_state.loadedData = false
    let serializedState = JSON.stringify(temp_state);
    window.sessionStorage.setItem("localStateName", serializedState);
  }

  initializeState() {
    return {};
  }
}
