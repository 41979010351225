import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
	checkLoggedinReq,
	loginReq,
	logoutReq,
	eIdBeginReq,
	eidloginReq
} from "../requests/loginRequests";

export const login = createAsyncThunk(
	"authUser/login",
	async (params, state) => {
		try {
			const resp = await loginReq(params.email, params.password);
			console.log("RESP Login: ", resp);
			return resp;
		} catch (err) {
			return state.rejectWithValue(err);
		}
	}
);

export const eidlogin = createAsyncThunk(
	"authUser/eidlogin",
	async (params, state) => {
		try {
			const resp = await eidloginReq(params.transaction_id);
			return resp;
		} catch (err) {
			return state.rejectWithValue(err);
		}
	}
);

export const eIdBegin = createAsyncThunk(
	"authUser/eidbegin",
	async (params, state) => {
		try {
			const resp = await eIdBeginReq();
			return resp;
		} catch (err) {
			return state.rejectWithValue(err);
		}
	}
);

export const logout = createAsyncThunk(
	"authUser/logout",
	async (params, state) => {
		const resp = await logoutReq();
		return resp;
	}
);

export const checkLoggedin = createAsyncThunk(
	"authUser/checkLoggedIn",
	async () => {
		const resp = await checkLoggedinReq();
		return resp;
	}
);

const initialState = {
	data: {},
	isAuthenticated: false,
	status: "idle",
	error: null,
	message: null,
	eidPending: false
};

const authUserSlice = createSlice({
	name: "authUser",
	initialState,
	reducers: {
		clearAuthUser: (state, action) => {
			state.data = {};
			state.isAuthenticated = false;
			state.error = null;
			state.message = null;
			state.status = "idle";
			state.eidPending = false;
		},
		resetAuthUserError: (state, action) => {
			state.error = null;
			state.message = null;
		}
	},
	extraReducers: (builder) => {
		// add reducers for thunk.
		// The 'createAsyncThunk' will dispatch an action with type pending || fulfilled || rejected
		// see https://redux-toolkit.js.org/api/createAsyncThunk
		builder
			.addCase(login.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isAuthenticated = true;
				state.status = "idle";
				state.message = "user logged in";
				state.error = null;
				state.eidPending = false;
			})
			.addCase(login.pending, (state, action) => {
				state.status = "pending";
				state.message = "logging in user";
			})
			.addCase(login.rejected, (state, action) => {
				state.status = "rejected";
				state.isAuthenticated = false;
				state.data = {};
				state.message = null;
				state.error = action.payload;
				state.eidPending = false;
			})
			.addCase(eIdBegin.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isAuthenticated = false;
				state.status = "idle";
				state.message = "eId authentication started";
				state.error = null;
				state.eidPending = true;
			})
			.addCase(eIdBegin.pending, (state, action) => {
				state.status = "pending";
				state.message = "starting eId authentication";
			})
			.addCase(eIdBegin.rejected, (state, action) => {
				state.status = "rejected";
				state.isAuthenticated = false;
				state.data = {};
				state.message = null;
				state.error = action.payload;
				state.eidPending = false;
			})
			.addCase(eidlogin.fulfilled, (state, action) => {
				state.data = action.payload;
				state.isAuthenticated = true;
				state.status = "idle";
				state.message = "eId authentication logged in";
				state.error = null;
				state.eidPending = false;
			})
			.addCase(eidlogin.pending, (state, action) => {
				state.status = "pending";
				state.message = "logging in with eId authentication";
				state.eidPending = false;
			})
			.addCase(eidlogin.rejected, (state, action) => {
				state.status = "rejected";
				state.isAuthenticated = false;
				state.data = {};
				state.message = null;
				state.eidPending = false;
				state.error = action.payload;
			})
			.addCase(logout.fulfilled, (state, action) => {
				state.status = "idle";
				state.isAuthenticated = false;
				state.data = {};
				state.message = "user logged out";
				state.error = null;
				state.eidPending = false;
			})
			.addCase(logout.pending, (state, action) => {
				state.status = "pending";
				state.message = "logging out user";
			})
			.addCase(logout.rejected, (state, action) => {
				state.status = "rejected";
				state.message = null;
				state.error = action.error.message;
				state.eidPending = false;
			})
			.addCase(checkLoggedin.fulfilled, (state, action) => {
				state.status = "idle";
				if (action.payload.isAuthenticated === true) {
					state.isAuthenticated = true;
					state.data = action.payload;
				} else {
					state.data = {};
					state.isAuthenticated = false;
				}
				state.error = null;
				state.message = "user is authenticated";
			})
			.addCase(checkLoggedin.pending, (state, action) => {
				state.status = "pending";
				state.message = "checking user";
			})
			.addCase(checkLoggedin.rejected, (state, action) => {
				state.status = "rejected";
				state.message = null;
				state.error = action.error.message;
				state.data = {};
				state.isAuthenticated = false;
				state.eidPending = false;
			});
	},
});

export const { clearAuthUser, resetAuthUserError } = authUserSlice.actions;

export default authUserSlice.reducer;
