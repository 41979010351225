import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

function PBIReport(props) {
  const embedUrl = useSelector((state) => state.PBICred.embedUrl);
  const accessToken = useSelector((state) => state.PBICred.accessToken);
  const reportID = useSelector((state) => state.PBICred.reportID);
  const pages = useSelector((state) => state.PBICred.pages);
  const { pageName } = useParams();
  const [pageExists, setPageExists] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    let exists = pages.filter((p) => p.name === pageName)[0];
    if (exists) {
      setPageExists(true);
    }
  }, [pageName]);

  return (
    <>
      {error ? (
        <h2 className="NoReportError">
          {" "}
          Något gick fel, ladda om sidan eller försök igen senare
        </h2>
      ) : embedUrl &&
        accessToken &&
        reportID &&
        pageExists &&
        !error &&
        !props.tokenExpired ? (
        <PowerBIEmbed
          embedConfig={{
            type: "report", // Supported types: report, dashboard, tile, visual and qna
            id: reportID,
            embedUrl: embedUrl,
            accessToken: accessToken,
            tokenType: models.TokenType.Embed,
            pageName: pageName,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              localeSettings: {
                language: "sv",
              },
              background: models.BackgroundType.Transparent,
              filterPaneEnabled: false,
              navContentPaneEnabled: false,
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  if (event.detail.message === "InvalidOrNoReportId") {
                    setError(true);
                  } else if (event.detail.message === "LoadReportFailed") {
                    setError(true);
                  } else if (event.detail.message === "TokenExpired") {
                    props.setTokenExpired(true);
                  } else if (event.detail.message === "HttpClientError") {
                    props.setTokenExpired(true);
                  }
                  console.log(event);
                },
              ],
            ])
          }
          cssClassName={"report-style-class"}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      ) : null}
    </>
  );
}

export default PBIReport;
